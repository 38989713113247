import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';
import AppleIcon from '@material-ui/icons/Apple';
import AndroidOutlinedIcon from '@material-ui/icons/AndroidOutlined';
import Page from 'src/components/Page';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import JWTConvertLogin from './JWTConvertLogin';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh'
  },
  banner: {
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  bannerChip: {
    marginRight: theme.spacing(2)
  },
  methodIcon: {
    height: 30,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  cardContainer: {
    paddingBottom: 80,
    paddingTop: 80
  },
  cardContent: {
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    minHeight: 400
  },
  currentMethodIcon: {
    height: 40,
    '& > img': {
      width: 'auto',
      maxHeight: '100%'
    }
  }
}));

const LoginComponent = props => {
  const classes = useStyles();
  const { onSwitchView } = props;

  return (
    <>
      {/*
      <Box mb={8} display="flex" justifyContent="center">
        <RouterLink to="/">
          <Logo styles={{ maxWidth: '50%' }} />
        </RouterLink>
      </Box>
    */}
      <Card>
        <CardContent className={classes.cardContent}>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
            mb={3}
          >
            <div>
              <Typography color="textPrimary" gutterBottom variant="h2">
                Sign in
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Sign into your Trackwired account.
              </Typography>
            </div>
          </Box>
          <Box flexGrow={1} mt={2}>
            <JWTConvertLogin />
          </Box>
          <Box my={3}>
            <Divider />
          </Box>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ cursor: 'pointer' }}
            onClick={() => onSwitchView()}
          >
            Don't have an account? <strong>Create a new account.</strong>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
};

LoginComponent.propTypes = {
  onSwitchView: PropTypes.func.isRequired
};

export default LoginComponent;
