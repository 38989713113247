import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Hidden,
  Typography,
  makeStyles
} from '@material-ui/core';
import RegisterComponent from '../RegisterComponent';
import LoginComponent from '../LoginComponent';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 100,
    paddingBottom: 200,
    [theme.breakpoints.down('md')]: {
      paddingTop: 60,
      paddingBottom: 60
    }
  },
  technologyIcon: {
    height: 40,
    margin: theme.spacing(1)
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  }
}));

const Hero = ({ className, ...rest }) => {
  const classes = useStyles();
  const [isLoginShow, setIsLoginShow] = useState(true);

  const handleSwitchView = () => {
    setIsLoginShow(!isLoginShow);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Hidden smDown>
            <Grid item xs={12} md={7}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height="100%"
              >
                <Typography variant="overline" color="secondary">
                  Welome to
                </Typography>
                <Typography variant="h1" color="textPrimary">
                  Trackwired
                </Typography>
                <Box mt={3}>
                  <Typography variant="body1" color="textSecondary">
                    Our mission at Trackwired is to create a social experience
                    through technology and products that will enhance the
                    performances of athletes, coaches, fans, parents, and
                    competitors in track and field, cross country, and road
                    running performance. The Trackwired team is comprised of
                    passionate leaders in the sports of track and field, cross
                    country, and road running. Train like a champion... Get
                    Trackwired!
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Typography variant="h1" color="secondary">
                        1000+
                      </Typography>
                      <Typography variant="overline" color="textSecondary">
                        Training Videos
                      </Typography>
                    </Grid>
                    {/*
                  <Grid item>
                    <Typography variant="h1" color="secondary">
                      UX
                    </Typography>
                    <Typography variant="overline" color="textSecondary">
                      Complete Flows
                    </Typography>
                  </Grid>
                  */}
                    <Grid item>
                      <Typography variant="h1" color="secondary">
                        100+
                      </Typography>
                      <Typography variant="overline" color="textSecondary">
                        Training Plans
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Hidden>
          <Grid item xs={12} md={5}>
            <Box position="relative">
              {!isLoginShow && (
                <RegisterComponent onSwitchView={handleSwitchView} />
              )}
              {isLoginShow && (
                <LoginComponent onSwitchView={handleSwitchView} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
