import React, { createContext, useEffect, useReducer, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { datadogLogs } from '@datadog/browser-logs';
import SplashScreen from '../components/SplashScreen';
import axiosInstance from '../utils/axios';
import firebase from 'src/lib/firebase';
import axios from 'axios';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  errorMessage: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        errorMessage: null,
        user
      };
    }

    case 'USER_DATA_CHANGED': {
      const { user } = action.payload;
      return {
        ...state,
        user
      };
    }

    // Might be able to remove the others below except the login_failure one

    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        errorMessage: null,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        errorMessage: null,
        user
      };
    }
    case 'LOGIN_FAILURE': {
      const { error } = action.payload;
      return {
        ...state,
        errorMessage: error
      };
    }
    case 'CREATE_ACCOUNT_FAILURE': {
      const { error } = action.payload;
      return {
        ...state,
        errorMessage: error
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    //SUBSCRIPTION_PLAN_CHECK
    case 'SUBSCRIPTION_PLAN_CHECK': {
      const { user, subscriptionPlan } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user: { ...user, subscriptionPlan: subscriptionPlan }
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'RESET_SUCCESS': {
      return {
        ...state,
        errorMessage: null
      };
    }
    case 'RESET_FAILURE': {
      const { error } = action.payload;
      return {
        ...state,
        errorMessage: error
      };
    }

    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWTConvert',
  registerWithEmail: () => Promise.resolve(),
  refreshUserData: () => Promise.resolve(),
  signInRegisterWithFacebook: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  checkSubscriptionChange: () => Promise.resolve(),
  logout: () => {},
  recover: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [user, setUser] = useState(null);
  const [preventAuthRedirect, setPreventAuthRedirect] = useState(true);

  /*
  useEffect(() => {
    initializeState();
  }, []);

  const initializeState = async () => {
    const _initState = await getLSItem('tw-state');
    console.log('Load Initial State from Storage', _initState);
    setAuthInfo(_initState ? JSON.parse(_initState) : null);
  };
  */

  const signInWithEmailAndPassword = async (email, password) => {
    // Posting to TW API to check for conversion
    const response = await axiosInstance.post(`token/firebase`, {
      username: email,
      password,
      grant_type: 'password',
      clientId: 'UC7CQJ'
    });

    console.log(response);

    // if true then attempt firebase signin

    // Firebase Converted User
   // if (response.data.isFirebaseUser === true) {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(response => {
          datadogLogs.logger.info('Firebase Email Login', {
            email: email,
            msg: 'success'
          });

          refreshUserData();
          // Call API to get info?
          /*
          dispatch({
            type: 'LOGIN',
            payload: {
              user
            }
          });
          */
        })
        .catch(err => {
          datadogLogs.logger.warn('Firebase Email Login', {
            email: email,
            msg: 'error',
            error: err
          });

          dispatch({
            type: 'LOGIN_FAILURE',
            payload: {
              error:
                err.message === 'INVALID_PASSWORD'
                  ? 'Invalid email or password. Please try again.'
                  : err.message
            }
          });
        });
    //}
    // Non Converted User
    /*
    else {
      // Incorrect Auth0 PW for Non-converted user
      if (response.data.isAuth0Authenticated === false) {
        dispatch({
          type: 'LOGIN_FAILURE',
          payload: { error: response.data.errorMessage.toString() }
        });
      }
      // Authenticated Auth0
      else {
        // Create Firebase New Account
        firebase
          .auth()
          .createUserWithEmailAndPassword(email, password)
          .then(firebaseResponse => {
            try {
              // Call TW API To update FirebaseUserId
              const response = axiosInstance.put(`token/firebase`, {
                uid: firebaseResponse.user.uid,
                email: email,
                platform: 'email'
              });
              // Load user data from api
              axiosInstance.get(`me`).then(response => {
                dispatch({
                  type: 'AUTH_STATE_CHANGED',
                  payload: {
                    isAuthenticated: true,
                    user: {
                      id: response.data.id,
                      avatar: firebaseResponse.photoURL,
                      email: firebaseResponse.email,
                      firstName: response.data.firstName,
                      lastName: response.data.lastName
                    }
                  }
                });
              });
            } catch (err) {
              dispatch({
                type: 'LOGIN_FAILURE',
                payload: { error: 'Error Connecting to TW API' }
              });
            }
          })
          .catch(err => {
            dispatch({
              type: 'LOGIN_FAILURE',
              payload: { error: err.message }
            });
          });
      }
    }

    */

    return;
  };

  const signInRegisterWithFacebook = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    //provider.addScope('user_birthday');
    //provider.addScope('user_gender');

    return (
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(result => {
          // 1. Get FB Access Token
          var token = result.credential.accessToken;
          var _additionalData = result.additionalUserInfo.profile;

          //Send to datadog
          datadogLogs.logger.info('Firebase Facebook Login', {
            email: result.user.email,
            msg: `Facebook Login Success: ${result.user.email}`,
            data: {
              facebookUserId: result.additionalUserInfo.id,
              profile: _additionalData || {}
            }
          });

          // 1. Update photo url
          // update display photo in Firebase so it's accessable in the user useAuth hook
          var user = firebase.auth().currentUser;
          console.log('Facebook USER', user);
          user
            .updateProfile({
              photoURL: _additionalData.picture.data.url
            })
            .then(function() {})
            .catch(function(error) {
              // An error happened.
              datadogLogs.logger.info('Facebook Login Error', {
                email: user.email,
                msg: `Facebook Login Error: ${user.email}`,
                data: error
              });
            });

          // 2. Send access token w/ firebaseUserId to api to create tw db user
          axiosInstance
            .post(`users/social`, {
              facebookUserId: _additionalData.id,
              email: result.user.email,
              firstName: _additionalData.first_name,
              lastName: _additionalData.last_name,
              gender: '',
              birthdate: '',
              avatarUrl: _additionalData.picture.data.url,
              accessToken: token,
              firebaseUserId: result.user.uid,
              platform: 'facebook'
            })
            .then(response => {
              // I don't think this is needed
              // Load user data from api
              if (response.status === 200) {
                axiosInstance.get(`me`).then(response => {
                  const _user = {
                    //id: response.data.id,
                    avatar: _additionalData.picture.data.url,
                    email: result.user.email,
                    firstName: _additionalData.first_name,
                    lastName: _additionalData.last_name,
                    name: '' //firebase_user.displayName || user.email,
                  };
                  setUser(_user);
                  dispatch({
                    type: 'AUTH_STATE_CHANGED',
                    payload: {
                      isAuthenticated: true,
                      user: _user
                    }
                  });
                });
              } else
                dispatch({
                  type: 'CREATE_ACCOUNT_FAILURE',
                  payload: { error: 'Error Creating User Account.' }
                });
            });
        })
        //.catch(err => console.log('FIREBASE ERROR -  ', err))
        .finally(() => {})
    );
  };

  // Coming next
  const signInWithApple = () => {};

  const logout = () => {
    return firebase.auth().signOut();
    /*
    localStorage.removeItem('tw-state');
    localStorage.removeItem('tw-tokens');
    dispatch({ type: 'LOGOUT' });
    */
  };

  const recover = async email => {
    try {
      const response = await axios.post(`users/recover`, {
        email
      });
      if (response.data) {
        dispatch({
          type: 'RESET_SUCCESS'
        });
      }
    } catch (err) {
      dispatch({
        type: 'RESET_FAILURE',
        payload: {
          error:
            'Error Recovering Account. Please email support sales@trackwired.com.'
        }
      });
    }
  };

  const registerWithEmail = async (
    email,
    password,
    firstName,
    lastName,
    gender,
    birthdate
  ) => {
    // 1. Call TW API to create firebase account and trackwired user

    try {
      const response = await axiosInstance.post(`users/email`, {
        email,
        password,
        firstName,
        lastName,
        gender,
        birthdate
      });

      if (response.data.success) {
        // 2. Account created on Firebase and TW DB
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(response => {})
          .catch(err => {
            dispatch({
              type: 'LOGIN_FAILURE',
              payload: { error: 'Invalid email or password. Please try again.' }
            });
          });
      } else {
        dispatch({
          type: 'CREATE_ACCOUNT_FAILURE',
          payload: { error: 'Error Creating User Account.' }
        });
      }
    } catch (err) {
      dispatch({
        type: 'CREATE_ACCOUNT_FAILURE',
        payload: { error: err.error_description }
      });
    }
  };

  const checkSubscriptionChange = async () => {
    const meResponse = axiosInstance.get(`me`).then(response => {
      const _sub = response.data.subscriptionPlan;
      // update local state
      setUser({
        ...user,
        subscriptionPlan: _sub
      });

      // Push to context so we can use it in useAuth hook
      dispatch({
        type: 'SUBSCRIPTION_PLAN_CHECK',
        payload: {
          user: user,
          subscriptionPlan: _sub
        }
      });
    });
  };

  const refreshUserData = async user => {
    setUser(user);
    dispatch({
      type: 'USER_DATA_CHANGED',
      payload: {
        user
      }
    });
  };

  const loadUserData = async firebase_user => {
    let _provider = '';

    firebase_user.providerData.forEach(function(providerData) {
      _provider = providerData.providerId;
    });

    const meResponse = axiosInstance.get(`me`).then(response => {


      const _user = {
        id: response.data.id,
        avatar: response.data.avatarUrl, //firebase_user.photoURL,
        email: response.data.email,//firebase_user.email,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        gender: response.data.gender,
        birthdate: response.data.birthdate,
        name: '', //firebase_user.displayName || user.email,
        provider: _provider,
        isSubscriber: response.data.isSubscriber,
        isPendingCancellation: response.data.isPendingCancellation,
        subscriptionPlan: response.data.subscriptionPlan,
        subscriptionPaymentMethod: response.data.subscriptionPaymentMethod
        //tier: '' //'elite-sub'
      };


      setUser(_user);

      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: true,
          user: _user
        }
      });
    });
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(firebase_user => {
      if (firebase_user) {
        // Here you should extract the complete user profile to make it available in your entire app.
        loadUserData(firebase_user);
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWTConvert',
        registerWithEmail,
        refreshUserData,
        signInRegisterWithFacebook,
        signInWithEmailAndPassword,
        checkSubscriptionChange,
        logout,
        recover
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
