import React, { useEffect } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Link,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import AppleIcon from '@material-ui/icons/Apple';
import FacebookIcon from '@material-ui/icons/Facebook';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

const useStyles = makeStyles(theme => ({
  root: {},
  googleButton: {
    backgroundColor: theme.palette.common.white
  },
  providerIcon: {
    marginRight: theme.spacing(2)
  },
  divider: {
    flexGrow: 1
  },
  dividerText: {
    margin: theme.spacing(2)
  }
}));

const JWTConvertLogin = ({ className, ...rest }) => {
  const classes = useStyles();
  const {
    signInWithEmailAndPassword,
    signInRegisterWithFacebook,
    errorMessage
  } = useAuth();
  const isMountedRef = useIsMountedRef();
  //const dispatch = useDispatch();
  //const { error } = useSelector(state => state.app);

  const handleFacebookClick = async () => {
    try {
      await signInRegisterWithFacebook();
    } catch (err) {
      if (err.code === 'auth/account-exists-with-different-credential')
        console.log('Migrate account to new FB!');
      // remove FB account with Firebase and add new account. Will need to update user.accounts in
    }
  };

  useEffect(() => {
    if (errorMessage != null) {
      //setStatus({ success: false });
      //setErrors({ submit: errorMessage });
    }
  }, [errorMessage]);

  return (
    <>
    {/*
      <Button
        className={classes.googleButton}
        fullWidth
        onClick={handleFacebookClick}
        size="large"
        variant="contained"
      >
        <FacebookIcon className={classes.providerIcon} />
        Sign In with Facebook
      </Button>
      <Box alignItems="center" display="flex" mt={2}>
        <Divider className={classes.divider} orientation="horizontal" />
        <Typography
          color="textSecondary"
          variant="body1"
          className={classes.dividerText}
        >
          OR
        </Typography>
        <Divider className={classes.divider} orientation="horizontal" />
      </Box>
      */}
      <Formik
        initialValues={{
          email: '',
          password: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
          password: Yup.string()
            .max(255)
            .required('Password is required')
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            //dispatch(loginWithEmail(values.email, values.password));

            await signInWithEmailAndPassword(values.email, values.password);
            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }


          } catch (err) {
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            className={clsx(classes.root, className)}
            {...rest}
          >
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />

            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            {errorMessage && (
              <Box mt={3}>
                <FormHelperText error>{errorMessage}</FormHelperText>
              </Box>
            )}

            <Box mt={2}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Log In
              </Button>
            </Box>

            <Box mt={2}>
              <Link
                component={RouterLink}
                to="/reset"
                variant="body2"
                color="textSecondary"
              >
                Forgot Password?
              </Link>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

JWTConvertLogin.propTypes = {
  className: PropTypes.string
};

export default JWTConvertLogin;
