import firebase from 'src/lib/firebase';

export const authHeader = async () => {
  // return auth header w/ jwt

  const twTokens = JSON.parse(localStorage.getItem('tw-tokens'));

  if (twTokens?.id_token) {
    return {
      ContentType: 'application/json',
      Accept: 'application/json',
      Authorization: 'Bearer ' + twTokens.id_token
    };
  } else return { Accept: 'application/json' };
};

export const getToken = async () => {
  try {
    return firebase.auth().currentUser.getIdToken();
  } catch (err) {}
};
/*
export const getToken = async () => {
  const twTokens = JSON.parse(await localStorage.getItem('tw-tokens'));
  console.log('TWTOKENS:', twTokens);
  return twTokens?.id_token ? twTokens.id_token : '';
};
*/

export const getRefreshToken = async () => {
  const twTokens = JSON.parse(await localStorage.getItem('tw-tokens'));
  return twTokens?.refreshToken ? twTokens.refreshToken : '';
};
