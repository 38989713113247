import axios from 'axios';
import { authHeader, getToken } from './authHelper';

//const baseURL = 'https://trackwired-api-v2.azurewebsites.net/api/';
const baseURL = `${process.env.REACT_APP_TW_API_PROTOCOL}://${process.env.REACT_APP_TW_API_HOST}/api/`;

let headers = {};
//let token = getToken();

/*
if (token) {
  headers.Accept = 'application/json';
  headers.Authorization = 'Bearer ' + token;
}
*/

const axiosInstance = axios.create({
  baseURL: baseURL
  // headers
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async config => {
    // Do something before request is sent.en
    var token = await getToken();
    if (token) config.headers['Authorization'] = 'Bearer ' + token;
    /*
    config.headers['Ocp-Apim-Subscription-Key'] =
      'be76a522dd834b14bad40a0bca8fa53d'; */
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

export default axiosInstance;
