import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import Features from './Features';

//import Testimonials from './Testimonials';
//import CTA from './CTA';
//import FAQS from './FAQS';

const useStyles = makeStyles(theme => ({
  root: {}
}));

const HomeView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Home">
      <Hero />
      <Box
        pt={15}
        display="flex"
        className="marginAuto"
        justifyContent="center"
      >
        <Typography color="secondary" variant="h6">
          v1.0.13
        </Typography>
      </Box>
    </Page>
  );
};

export default HomeView;
