import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import HomeView from './views/home/HomeView';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/_auth_NOT_USED/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset',
    component: lazy(() => import('src/views/_auth_NOT_USED/ResetView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/_auth_NOT_USED/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/education',
        component: lazy(() => import('src/views/education/EducationView'))
      },
      {
        exact: true,
        path: '/app/education/:id',
        component: lazy(() =>
          import('src/views/education/EducationView/Detail')
        )
      },
      {
        exact: true,
        path: '/app/dashboard',
        component: lazy(() => import('src/views/dashboard/DashboardView'))
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/teams',
        component: lazy(() => import('src/views/teams/BrowseView'))
      },
      {
        exact: true,
        path: '/app/teams-requests',
        component: lazy(() => import('src/views/teams/TeamRequestView'))
      },
      {
        exact: true,
        path: '/app/teams/:id',
        component: lazy(() => import('src/views/teams/TeamView'))
      },
      {
        exact: true,
        path: '/app/workoutbuilder/library',
        component: lazy(() =>
          import('src/views/workoutBuilder/WorkoutBuilderView/Library')
        )
      },
      {
        exact: true,
        path: '/app/workoutbuilder/training-plans',
        component: lazy(() =>
          import('src/views/workoutBuilder/WorkoutBuilderView/TrainingPlans')
        )
      },
      {
        exact: true,
        path: '/app/workoutbuilder/training-plans/:id',
        component: lazy(() =>
          import('src/views/workoutBuilder/WorkoutBuilderView/Builder')
        )
      },
      {
        exact: true,
        path: '/app/mytrainingplans',
        component: lazy(() =>
          import('src/views/myTrainingPlans/MyTrainingPlansView')
        )
      },
      // TW Import
      {
        exact: true,
        path: '/app/mytrainingplans/:id/tw/import/team',
        component: lazy(() =>
          import(
            'src/views/myTrainingPlans/MyTrainingPlansView/Import/Trackwired/TeamImport'
          )
        )
      },
      {
        exact: true,
        path: '/app/mytrainingplans/:id/tw/import/user',
        component: lazy(() =>
          import(
            'src/views/myTrainingPlans/MyTrainingPlansView/Import/Trackwired/UserImport'
          )
        )
      },
      // Custom Import
      {
        exact: true,
        path: '/app/mytrainingplans/:id/custom/import/team',
        component: lazy(() =>
          import(
            'src/views/myTrainingPlans/MyTrainingPlansView/Import/Custom/TeamImport'
          )
        )
      },
      {
        exact: true,
        path: '/app/mytrainingplans/:id/custom/import/user',
        component: lazy(() =>
          import(
            'src/views/myTrainingPlans/MyTrainingPlansView/Import/Custom/UserImport'
          )
        )
      },
      {
        exact: true,
        path: '/app/trackwired-training',
        component: lazy(() => import('src/views/subscription/SubscriptionView'))
      },
      {
        exact: true,
        path: '/app/trackwired-training/:id',
        component: lazy(() => import('src/views/subscription/CollectionView'))
      },
      {
        exact: true,
        path: '/app/trackwired-training/:id/:productId',
        component: lazy(() => import('src/views/subscription/ProductView'))
      },

      {
        exact: true,
        path: '/app/import/trackwired/:id/user',
        component: lazy(() =>
          import('src/views/importTool/TrackwiredImportView/UserImport')
        )
      },
      {
        exact: true,
        path: '/app/import/trackwired/:id/team',
        component: lazy(() =>
          import('src/views/importTool/TrackwiredImportView/TeamImport')
        )
      },
      {
        exact: true,
        path: '/app/videos',
        component: lazy(() => import('src/views/videos/VideoView'))
      },
      {
        exact: true,
        path: '/app/videos/:id',
        component: lazy(() => import('src/views/videos/VideoListingView'))
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        guard: GuestGuard,
        component: HomeView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
