import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import moment from 'moment';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormHelperText,
  Grid,
  Link,
  Select,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AppleIcon from '@material-ui/icons/Apple';
import FacebookIcon from '@material-ui/icons/Facebook';
import MailIcon from '@material-ui/icons/Mail';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';

const useStyles = makeStyles(theme => ({
  root: {},
  googleButton: {
    backgroundColor: theme.palette.common.white
  },
  providerIcon: {
    marginRight: theme.spacing(2)
  },
  divider: {
    flexGrow: 1
  },
  dividerText: {
    margin: theme.spacing(2)
  },
  gender: {
    marginTop: '16px',
    marginBottom: '8px'
  },
  datePicker: {
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  }
}));

const minBirthdate = moment()
  .subtract(13, 'years')
  .calendar();

const genders = [
  { text: 'Female', value: 'F' },
  { text: 'Male', value: 'M' }
];

const FirebaseRegister = ({ className, ...rest }) => {
  const classes = useStyles();
  const {
    registerWithEmail,
    signInRegisterWithFacebook,
    errorMessage
  } = useAuth();
  const isMountedRef = useIsMountedRef();
  const [method, setMethod] = useState('');

  const handleEmailClick = async () => {
    setMethod('email');
  };

  const handleFacebookClick = async () => {
    //setMethod('facebook');

    try {
      await signInRegisterWithFacebook();
    } catch (err) {
      console.error(err);
    }
  };

  const handleAppleClick = async () => {
    setMethod('apple');
    /*
    try {
      await signInWithGoogle();
    } catch (err) {
      console.error(err);
    }
    */
  };

  useEffect(() => {
    if (errorMessage != null) {
      //setStatus({ success: false });
      //setErrors({ submit: errorMessage });
    }
  }, [errorMessage]);

  return (
    <>
      <>
        {/*}
        <Button
          className={classes.googleButton}
          fullWidth
          onClick={handleEmailClick}
          size="large"
          variant="contained"
        >
          <MailIcon className={classes.providerIcon} />
          Sign Up with Email
        </Button>
        */}
        {/* Remove FB login for now.
        <Button
          className={classes.googleButton}
          fullWidth
          onClick={handleFacebookClick}
          size="large"
          variant="contained"
        >
          <FacebookIcon className={classes.providerIcon} />
          Sign Up with Facebook
        </Button>
        <Box alignItems="center" display="flex" mt={2}>
          <Divider className={classes.divider} orientation="horizontal" />
          <Typography
            color="textSecondary"
            variant="body1"
            className={classes.dividerText}
          >
            OR
          </Typography>
          <Divider className={classes.divider} orientation="horizontal" />
        </Box>
        */}
        {/*
          <Box alignItems="center" display="flex" mt={2}>
            <Divider className={classes.divider} orientation="horizontal" />
          </Box>
          <Button
            className={classes.googleButton}
            fullWidth
            onClick={handleAppleClick}
            size="large"
            variant="contained"
          >
            <AppleIcon className={classes.providerIcon} />
            Sign Up with Apple for Free
          </Button>
          */}
      </>

      <Formik
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          password: '',
          birthdate: minBirthdate,
          policy: false,
          gender: '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .min(3)
            .max(255)
            .required('First name is required'),
          lastName: Yup.string()
            .min(3)
            .max(255)
            .required('Last name is required'),
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
          password: Yup.string()
            .min(7)
            .max(255)
            .required('Password is required'),
          gender: Yup.string()
            .min(1)
            .required('Gender is required'),
          birthdate: Yup.date()
            .typeError('Invalid Date')
            //.min(9)
            //.max(minBirthdate, `Birth Date must be less than ${minBirthdate}`)
            .required('Birth Date is required'),
          policy: Yup.boolean().oneOf(
            [true],
            'Terms and Conditions must be checked'
          )
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await registerWithEmail(
              values.email,
              values.password,
              values.firstName,
              values.lastName,
              values.gender,
              moment(values.birthdate).format('YYYY-MM-DD')
              //method
            );

            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
          touched,
          values
        }) => (
          <form
            noValidate
            className={clsx(classes.root, className)}
            onSubmit={handleSubmit}
            {...rest}
          >
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextField
                  error={Boolean(touched.firstName && errors.firstName)}
                  fullWidth
                  helperText={touched.firstName && errors.firstName}
                  label="First Name"
                  margin="normal"
                  name="firstName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.firstName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  error={Boolean(touched.lastName && errors.lastName)}
                  fullWidth
                  helperText={touched.lastName && errors.lastName}
                  label="Last Name"
                  margin="normal"
                  name="lastName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.lastName}
                  variant="outlined"
                />
              </Grid>
            </Grid>

            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Select
                  native
                  label="Gender"
                  className={classes.gender}
                  error={Boolean(touched.gender && errors.gender)}
                  helperText={touched.gender && errors.gender}
                  id="gender"
                  name="gender"
                  value={values.gender}
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                >
                  <option aria-label="None" value="">
                    Select Gender
                  </option>
                  {genders?.map(x => (
                    <option value={x.id} key={x.id}>
                      {x.text}
                    </option>
                  ))}
                </Select>
              </Grid>
              <Grid item md={6} xs={12}>
                <KeyboardDatePicker
                  fullWidth
                  //className={classes.datePicker}
                  error={Boolean(touched.birthdate && errors.birthdate)}
                  helperText={touched.birthdate && errors.birthdate}
                  label="Birth Date"
                  format="MM/DD/YYYY"
                  name="birthdate"
                  inputVariant="outlined"
                  margin="normal"
                  value={values.birthdate}
                  maxDate={minBirthdate}
                  onBlur={() => setFieldTouched('birthdate')}
                  onClose={() => setFieldTouched('birthdate')}
                  onAccept={() => setFieldTouched('birthdate')}
                  onChange={date => setFieldValue('birthdate', date)}
                />
              </Grid>
            </Grid>

            <Box alignItems="center" display="flex" mt={2} ml={-1}>
              <Checkbox
                checked={values.policy}
                name="policy"
                onChange={handleChange}
              />
              <Typography variant="body2" color="textSecondary">
                I have read the{' '}
                <Link
                  component="a"
                  href="https://www.trackwired.com/policies/terms-of-service"
                  target="_blank"
                  color="secondary"
                >
                  Terms and Conditions
                </Link>
              </Typography>
            </Box>
            {Boolean(touched.policy && errors.policy) && (
              <FormHelperText error>{errors.policy}</FormHelperText>
            )}
            {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}

            {errorMessage && (
              <Box mt={3}>
                <FormHelperText error>{errorMessage}</FormHelperText>
              </Box>
            )}

            <Box mt={2}>
              <Button
                color="secondary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Register
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default FirebaseRegister;
