import React from 'react';
import ReactDOM from 'react-dom';
//import { Provider } from 'react-redux';
import './index.css';
import App from './App';
//import store from 'src/store';
import 'react-quill/dist/quill.snow.css';
import * as serviceWorker from './serviceWorker';
import { datadogLogs } from '@datadog/browser-logs';

datadogLogs.init({
  clientToken: 'pub52c7554d5acaf9b0c95ecdd73a725b22',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: 100
});

const container = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  container
);

// Attach root container
//debugContextDevtool(container, {});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
