/*
export const auth0Config = {
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH0_DOMAIN
  };
  */

export const firebaseConfig = {
  apiKey: 'AIzaSyBIxA933FZumw-jOAkpiQEE3l0_Qd6DmUM',
  authDomain: 'trackwired.firebaseapp.com',
  databaseURL: 'https://trackwired.firebaseio.com',
  projectId: 'trackwired',
  storageBucket: 'trackwired.appspot.com',
  messagingSenderId: '924407373359',
  appId: '1:924407373359:web:dec8099c86f6247afe30da'
  /*
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
  */
};
