import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  isAuthenticated: false,
  isInitialised: true,
  user: null,
  error: null
};

/* Working */
const setSession = accessToken => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    logout(state) {
      setSession(null);
      state = {
        ...state,
        isAuthenticated: false,
        user: null
      };
    },
    loginWithEmail(state, action) {
      state.isAuthenticated = true;
    },
    loginFailure(state, action) {
      const { error_description } = action.payload;
      state.error = error_description;
    },
    getNotifications(state, action) {
      const { notifications } = action.payload;

      state.notifications = notifications;
    }
  }
});

export const reducer = slice.reducer;

export const logout = () => async dispatch => {
  dispatch(slice.actions.logout());
};

export const loginWithEmail = (email, password) => async dispatch => {
  try {
    const response = await axios.post('token', {
      username: email,
      password: password,
      grant_type: 'password',
      clientId: 'UC7CQJ'
    });
    // happy path
    dispatch(slice.actions.loginWithEmail(response.data));
  } catch (error) {
    dispatch(slice.actions.loginFailure(error));
  }
};

export const getNotifications = () => async dispatch => {
  const response = await axios.get('notifications');
  dispatch(slice.actions.getNotifications(response.data));
};

export default slice;
