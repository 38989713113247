import React from 'react';
import { Router } from 'react-router-dom';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import { createTheme } from './theme';
import MomentUtils from '@date-io/moment';
import './App.css';
import routes, { renderRoutes } from './routes';
import useSettings from './hooks/useSettings';
import { AuthProvider } from './contexts/JWTConvertAuthContext';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
//import { AuthProvider } from './contexts/FirebaseAuthContext';
import GlobalStyles from './components/GlobalStyles';
import ScrollReset from './components/ScrollReset';
import CookiesNotification from './components/CookiesNotification';
import 'src/theme/mobiscroll-overrides.css';
// import Calendar and Form components

const App = () => {
  const { settings } = useSettings();
  const history = createBrowserHistory();
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider dense maxSnack={3}>
          <Router history={history}>
            <AuthProvider>
              <CookiesNotification />
              {renderRoutes(routes)}
              <GlobalStyles />
              <ScrollReset />
            </AuthProvider>
          </Router>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
